export const pokemonTypes = [
  "grass/poison",
  "fire",
  "fire/flying",
  "water",
  "bug",
  "bug/flying",
  "bug/poison",
  "normal/flying",
  "normal",
  "poison",
  "electric",
  "ground",
  "poison/ground",
  "fairy",
  "normal/fairy",
  "poison/flying",
  "bug/grass",
  "fighting",
  "water/fighting",
  "psychic",
  "water/poison",
  "rock/ground",
  "water/psychic",
  "electric/steel",
  "water/ice",
  "ghost/poison",
  "grass/psychic",
  "ground/rock",
  "grass",
  "psychic/fairy",
  "ice/psychic",
  "water/flying",
  "rock/water",
  "rock/flying",
  "ice/flying",
  "electric/flying",
  "dragon",
  "dragon/flying",
  "normal/fighting",
  "normal/poison",
  "normal/ground",
  "normal/rock",
  "normal/bug",
  "normal/ghost",
  "normal/steel",
  "normal/fire",
  "normal/water",
  "normal/grass",
  "normal/electric",
  "normal/psychic",
  "normal/ice",
  "normal/dragon",
  "normal/dark",
  "fighting/flying",
  "fighting/poison",
  "fighting/ground",
  "fighting/rock",
  "fighting/bug",
  "fighting/ghost",
  "fighting/steel",
  "fighting/fire",
  "fighting/grass",
  "fighting/electric",
  "fighting/psychic",
  "fighting/ice",
  "fighting/dragon",
  "fighting/dark",
  "fighting/fairy",
  "flying",
  "flying/ground",
  "flying/ghost",
  "flying/steel",
  "flying/grass",
  "flying/psychic",
  "flying/dark",
  "flying/fairy",
  "poison/rock",
  "poison/steel",
  "poison/fire",
  "poison/electric",
  "poison/psychic",
  "poison/ice",
  "poison/dragon",
  "poison/dark",
  "poison/fairy",
  "ground/bug",
  "ground/ghost",
  "ground/steel",
  "ground/fire",
  "ground/water",
  "ground/grass",
  "ground/electric",
  "ground/psychic",
  "ground/ice",
  "ground/dragon",
  "ground/dark",
  "ground/fairy",
  "rock",
  "rock/bug",
  "rock/ghost",
  "rock/steel",
  "rock/fire",
  "rock/grass",
  "rock/electric",
  "rock/psychic",
  "rock/ice",
  "rock/dragon",
  "rock/dark",
  "rock/fairy",
  "bug/ghost",
  "bug/steel",
  "bug/fire",
  "bug/water",
  "bug/electric",
  "bug/psychic",
  "bug/ice",
  "bug/dragon",
  "bug/dark",
  "bug/fairy",
  "ghost",
  "ghost/steel",
  "ghost/fire",
  "ghost/water",
  "ghost/grass",
  "ghost/electric",
  "ghost/psychic",
  "ghost/ice",
  "ghost/dragon",
  "ghost/dark",
  "ghost/fairy",
  "steel",
  "steel/fire",
  "steel/water",
  "steel/grass",
  "steel/psychic",
  "steel/ice",
  "steel/dragon",
  "steel/dark",
  "steel/fairy",
  "fire/water",
  "fire/grass",
  "fire/electric",
  "fire/psychic",
  "fire/ice",
  "fire/dragon",
  "fire/dark",
  "fire/fairy",
  "water/grass",
  "water/electric",
  "water/dragon",
  "water/dark",
  "water/fairy",
  "grass/electric",
  "grass/ice",
  "grass/dragon",
  "grass/dark",
  "grass/fairy",
  "electric/psychic",
  "electric/ice",
  "electric/dragon",
  "electric/dark",
  "electric/fairy",
  "psychic/dragon",
  "psychic/dark",
  "ice",
  "ice/dragon",
  "ice/dark",
  "ice/fairy",
  "dragon/dark",
  "dragon/fairy",
  "dark",
  "dark/fairy",
];

export const pokeApiQuery = {
  1: { earlierGenPokemon: 0, pokePerGen: 151 },
  2: { earlierGenPokemon: 151, pokePerGen: 100 },
  3: { earlierGenPokemon: 251, pokePerGen: 135 },
  4: { earlierGenPokemon: 386, pokePerGen: 107 },
  5: { earlierGenPokemon: 493, pokePerGen: 156 },
  6: { earlierGenPokemon: 649, pokePerGen: 72 },
  7: { earlierGenPokemon: 721, pokePerGen: 88 },
  8: { earlierGenPokemon: 809, pokePerGen: 89 },
};
