import React from "react";
import { useSelector } from "react-redux";

import {
  finalPagePictureToDisplay,
  finalPageTextToDisplay,
} from "../../functions/finalPageFunctions";

import("./FinalPageImageAndText.css");

export default function FinalPageImageAndText({ score, isHighScore }) {
  const difficulty = useSelector((state) => state.difficulty);

  const { fileName, altText } = finalPagePictureToDisplay(
    difficulty,
    score,
    isHighScore
  );

  const { text, exclamation } = finalPageTextToDisplay(
    difficulty,
    score,
    isHighScore
  );

  return (
    <section>
      <div className="final_page_image_wrapper">
        <img className="final_page_image" src={fileName} alt={altText} />
      </div>
      <div>
        <p className="final_page_text_paragraph">{exclamation}</p>
        <p className="final_page_text_paragraph">{text}</p>
      </div>
    </section>
  );
}
