export const SET_DIFFICULTY = "SET_DIFFICULTY";

export const SET_PAGE = "SET_PAGE";

export const INCREASE_SCORE = "INCREASE_SCORE";

export const RESET_GAME = "RESET_GAME";

export const UPDATE_HIGH_SCORES = "UPDATE_HIGH_SCORES";

export const SET_POKEMON_GENERATION = "SET_POKEMON_GENERATION";
